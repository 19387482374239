var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-3"},[_c('div',{staticClass:"dashboard__container--body"},[(!_vm.clients || _vm.clients.length == 0)?_c('Loader'):_vm._e(),(_vm.clients && _vm.clients.length >= 1)?_c('div',{staticClass:"flex",staticStyle:{"width":"100%"}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.clientVenues,"styleClass":"vgt-table striped","search-options":{
            enabled: true,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 10,
          }},on:{"on-row-click":_vm.onRowClick}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }